import React from "react"
import {Row, Col, Table} from 'react-bootstrap';

export default () => (
  <Row>
    <Col md={{span: 6, offset: 3}} style={{textAlign: 'center'}}>
      <div className="" style={{marginTop: '100px'}}>
        <img src="/logo-transparent.png" style={{height: '70px'}}/>
        <h1 style={{marginTop: 30}}>404</h1>
        <h4 style={{marginTop: 30}}>Page not found</h4>
      </div>
    </Col>
  </Row>
)
